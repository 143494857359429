import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
  authorized: false,
  authenticationError: false,
  authenticationMessage: '',
  showResetPwdDialog: false,
  showTokenRefreshDialog: false,
  refreshTokenTimerId: null,
  isAuthenticating: false,
  verifyingToken: false,
  verifyTokenError: false,
  verifyTokenMessage: '',
  resettingPasswords: [],
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
