const changeCustomerInfo = async (
  { commit, getters, rootGetters, dispatch },
  data
) => {
  commit('SET_CHANGE_CUSTOMER_INFO_LOADING', true);
  try {
    const customer = getters['CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    if (customer && customer.customer_id === 0) {
      data.destination_id = selected_customer.customer_id;
    }

    const response = await window.axios.post(
      '/proxy/change-customer-info',
      data
    );
    if (!response.data.success) {
      throw response.data;
    }
    dispatch('getCustomerData');
    dispatch(
      'notifications/pushNotification',
      'Customer info updated successfully.',
      {
        root: true,
      }
    );
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_CHANGE_CUSTOMER_INFO_LOADING', false);
  }
};

export default changeCustomerInfo;
