const updateWorkflowVersion = async ({ commit, rootGetters, dispatch }, data) => {
    commit('SET_UPDATE_WORKFLOW_VERSION_LOADING', true);
    try {
        const customer = rootGetters['customer/CUSTOMER'];
        const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

        let url = `/proxy/get-workflows/${data.workflow_id}/versions/${data.version_id}`;
        let customer_id = customer.customer_id;
        if (customer_id === 0) {
            customer_id = selected_customer.customer_id;
        }

        data = { customer_id, ...data, };
        const response = await window.axios.put(url, data);

        if (response && response.data.success) {
            const { version } = response.data;
            return version;
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch('notifications/setError', error.message, { root: true });
    } finally {
        // stuff here
        commit('SET_UPDATE_WORKFLOW_VERSION_LOADING', false);
    }
};

export default updateWorkflowVersion;
