export default {
  SET_CUSTOMER: (state, payload) => {
    state.customer = payload;
  },
  SET_USER: (state, payload) => {
    state.user = payload;
  },
  UPD_CUSTOMER: (state, payload) => {
    state.customer = {
      ...state.customer,
      ...payload,
    };
  },
  SET_CUSTOMER_USERS: (state, payload) => {
    state.customer_users = payload;
  },
  UPD_CUSTOMER_USERS: (state, payload) => {
    state.customer_users = {
      ...state.customer_users,
      ...payload,
    };
  },
  SET_CUSTOMERS: (state, payload) => {
    state.customers = payload;
  },
  SET_SELECTED_CUSTOMER: (state, payload) => {
    state.selected_customer = payload;
  },
  SET_CUSTOMER_MAPPINGS: (state, payload) => {
    state.customerMappings = payload;
  },
  UPD_LOCATIONS: (state, payload) => {
    state.customer = {
      ...state.customer,
      Locations: {
        ...state.customer.Locations,
        [payload.index]: payload.location,
      },
    };
  },
  UPD_EMAIL: (state, payload) => {
    state.customer.user = {
      ...state.customer.user,
      email: payload,
    };
  },
  SET_CUSTOMER_USERS_LOADING: (state, payload) => {
    state.customerUsersLoading = payload;
  },
  SET_CUSTOMER_DATA_LOADING: (state, payload) => {
    state.customerDataLoading = payload;
  },
  SET_DELETE_CUSTOMER_USERS_LOADING: (state, payload) => {
    state.deleteCustomerUsersLoading = payload;
  },
  SET_ADD_CUSTOMER_USER_LOADING: (state, payload) => {
    state.addCustomerUserLoading = payload;
  },
  SET_GET_LOGGED_IN_USER_LOADING: (state, payload) => {
    state.getLoggedInUserLoading = payload;
  },
  SET_FEATURE_FLAGS: (state, payload) => {
    state.featureFlags = payload;
  },
  SET_GET_FEATURE_FLAGS_LOADING: (state, payload) => {
    state.getFeatureFlagsLoading = payload;
  },
  SET_CHANGE_CUSTOMER_INFO_LOADING: (state, payload) => {
    state.changeCustomerInfoLoading = payload;
  },
};
