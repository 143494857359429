const setWorkflowVersion = async ({ commit, rootGetters, dispatch }, { workflow_id, version_id}) => {
    commit('SET_FETCH_AUTOMATION_WORKFLOW_LOADING', true);
    commit('SET_SELECT_WORKFLOW_VERSION_LOADING', true);
    try {
        const customer = rootGetters['customer/CUSTOMER'];
        const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

        let url = `/proxy/get-workflows/${workflow_id}/versions/${version_id}`;
        let customer_id = customer.customer_id;
        if (customer_id === 0) {
            customer_id = selected_customer.customer_id;
        }

        const data = { customer_id };
        const response = await window.axios.post(url, data);

        if (response && response.data.success) {
            const { version } = response.data;
            commit('UPDATE_AUTOMATION_WORKFLOW', version.data);
            commit('SET_ALERT_SETTINGS', version.data.alertSettings);
            commit('SET_S3_STORAGE_ENABLED', version.data.IOStorageType === 's3');
            commit('SET_WORKFLOW_SELECT_VERSION', version)
            dispatch('workflowSetup');
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch('notifications/setError', error.message, { root: true });
    } finally {
        // stuff here
        commit('SET_FETCH_AUTOMATION_WORKFLOW_LOADING', false);
        commit('SET_SELECT_WORKFLOW_VERSION_LOADING', false);
    }
};

export default setWorkflowVersion;
