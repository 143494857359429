import cloneDeep from 'lodash/cloneDeep';
import { workflowStatus } from "@/util/workflow-statuses";

const startWorkflow = async ({ rootGetters, getters, dispatch, commit }, { production, workflowId }) => {
  commit("SET_START_WORKFLOW_LOADING", true);

  try {
    const customer = rootGetters["customer/CUSTOMER"];
    const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
    const workflow = getters['WORKFLOW'];
    const version = getters['WORKFLOW_SELECTED_VERSION'];
    const publishedVersion = getters['WORKFLOW_PUBLISHED_VERSION'];
    let running = cloneDeep(getters['WORKFLOW_RUNNING_OBJ'](workflowId));

    let url = "/proxy/run-workflow";
    const customerID =
      customer.customer_id === 0
        ? selected_customer.customer_id
        : customer.customer_id;

    const data = {
      customer_id: customerID,
      workflow_id: workflow.id,
      test_mode: !production,
    };

    if(version.id !== publishedVersion.id) {
      data.version_id = version.id;
    }

    const response = await window.axios.post(url, data);

    if (!response.data.success) {
      running = {
        ...running,
        workflow_status: workflowStatus.FAILURE,
        workflowId
      };
      commit('SET_WORKFLOW_RUNNING_OBJ', running);
    } else {
      const { storage_id } = response.data;
      setTimeout(() => {
        dispatch('getWorkflowStatus', { storage_id, firstCall: true, workflowId, isServerless: workflow.isServerless || false });
      }, 500);
    }

  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
    return { success: false, message: error.message };
  } finally {
    commit("SET_START_WORKFLOW_LOADING", false);
  }
};

export default startWorkflow;
