export default {
  AUTH: (state) => {
    return state.authorized;
  },
  AUTHENTICATION_ERROR: (state) => {
    return state.authenticationError;
  },
  AUTHENTICATION_MESSAGE: (state) => {
    return state.authenticationMessage;
  },
  SHOW_PASSWORD_RESET_DIALOG: (state) => {
    return state.showResetPwdDialog;
  },
  SHOW_TOKEN_REFRESH_DIALOG: (state) => {
    return state.showTokenRefreshDialog;
  },
  IS_AUTHENTICATING: (state) => {
    return state.isAuthenticating;
  },
  VERIFYING_TOKEN: (state) => {
    return state.verifyingToken;
  },
  VERIFY_TOKEN_ERROR: (state) => {
    return state.verifyTokenError;
  },
  VERIFY_TOKEN_MESSAGE: (state) => {
    return state.verifyTokenMessage;
  },
  RESENDING_TOKEN: (state) => {
    return state.resendingToken;
  },
  RESETTING_PASSWORDS: (state) => {
    return state.resettingPasswords;
  },
};
