import router from '@/router';

const getLoggedInUser = async ({ commit, dispatch }) => {
  commit('SET_GET_LOGGED_IN_USER_LOADING', true);
  try {
    const { data } = await window.axios.get('/proxy/get-logged-in-user');

    if (data && data.success === true) {
      const { user } = data;
      commit('SET_USER', { ...user });
      if (user.forceResetPassword) {
        router.push({ path: '/settings' });
      }
    } else {
      throw data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_GET_LOGGED_IN_USER_LOADING', false);
  }
};

export default getLoggedInUser;
