const publishWorkflowVersion = async ({ commit, rootGetters, dispatch }, { workflow_id, version_id}) => {
    commit('SET_PUBLISH_WORKFLOW_VERSION_LOADING', true);
    try {
        const customer = rootGetters['customer/CUSTOMER'];
        const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

        let url = `/proxy/get-workflows/${workflow_id}/versions/${version_id}/publish`;
        let customer_id = customer.customer_id;
        if (customer_id === 0) {
            customer_id = selected_customer.customer_id;
        }

        const data = { customer_id };
        const response = await window.axios.post(url, data);

        if (response && response.data.success) {
            const { workflow } = response.data;
            commit('SET_WORKFLOW_PUBLISHED_VERSION', workflow.published);
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch('notifications/setError', error.message, { root: true });
    } finally {
        // stuff here
        commit('SET_PUBLISH_WORKFLOW_VERSION_LOADING', false);
    }
};

export default publishWorkflowVersion;
