import getWorkspace from "@/store/ai-workspace/actions/getWorkspace";
import updateWorkspace from "@/store/ai-workspace/actions/updateWorkspace";
import getWorkspaceSessions from "@/store/ai-workspace/actions/getWorkspaceSessions";
import getWorkspaces from "@/store/ai-workspace/actions/getWorkspaces";
import createWorkspace from "@/store/ai-workspace/actions/createWorkspace";
import updateParticipants from "@/store/ai-workspace/actions/updateParticipants";
import updateWorkspacePrompt from "@/store/ai-workspace/actions/updateWorkspacePrompt";
import getWorkspacePrompts from "@/store/ai-workspace/actions/getWorkspacePrompts";
import deleteWorkspacePrompt from "@/store/ai-workspace/actions/deleteWorkspacePrompt";
import createWorkspacePrompt from "@/store/ai-workspace/actions/createWorkspacePrompt";
import addWorkspaceKnowledge from "@/store/ai-workspace/actions/addWorkspaceKnowledge";
import deleteWorkspaceKnowledge from "@/store/ai-workspace/actions/deleteWorkspaceKnowledge";
import getWorkspaceKnowledge from "@/store/ai-workspace/actions/getWorkspaceKnowledge";
import getKnowledgeRawText from "@/store/ai-workspace/actions/getKnowledgeRawText";
import deleteWorkspace from "@/store/ai-workspace/actions/deleteWorkspace";

const actions = {
    getWorkspaces,
    getWorkspace,
    deleteWorkspace,
    getWorkspaceSessions,
    updateWorkspace,
    createWorkspace,
    updateParticipants,
    updateWorkspacePrompt,
    getWorkspacePrompts,
    deleteWorkspacePrompt,
    createWorkspacePrompt,
    addWorkspaceKnowledge,
    deleteWorkspaceKnowledge,
    getWorkspaceKnowledge,
    getKnowledgeRawText,
}

export default actions;