const getWorkflowVersions = async ({ commit, rootGetters, dispatch }, data) => {
    commit('SET_WORKFLOW_VERSIONS_LOADING', true);
    try {
        const customer = rootGetters['customer/CUSTOMER'];

        const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

        let url = `/proxy/get-workflows/${data.workflow_id}/versions?search=${data.search}`;
        let customer_id = customer.customer_id;
        if (customer_id === 0) {
            customer_id = selected_customer.customer_id;
        }

        data = { customer_id };
        const response = await window.axios.post(url, data);

        if (response && response.data.success) {
            const { versions } = response.data;
            commit('SET_WORKFLOW_VERSIONS', versions);
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch('notifications/setError', error.message, { root: true });
    } finally {
        // stuff here
        commit('SET_WORKFLOW_VERSIONS_LOADING', false);
    }
};

export default getWorkflowVersions;
