const passwordReset = async ({ commit, dispatch, state }, { email }) => {
  commit('SET_IS_AUTHENTICATING', true);
  commit('SET_AUTHENTICATION_ERROR', false);
  try {
    commit('SET_RESETTING_PASSWORDS', [...state.resettingPasswords, email]);
    const response = await window.axios.post('/proxy/reset-password', {
      email,
    });
    if (!response?.data?.success && response?.data?.message) {
      throw { message: response.data.message };
    }
    commit('SET_SHOW_PASSWORD_RESET_DIALOG', true);
    dispatch('notifications/pushNotification', 'Password reset email sent.', {
      root: true,
    });
  } catch (error) {
    commit('SET_AUTHENTICATION_ERROR', true);
    commit('SET_AUTHENTICATION_MESSAGE', error.message || 'Unknown error');
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_IS_AUTHENTICATING', false);
    commit(
      'SET_RESETTING_PASSWORDS',
      state.resettingPasswords.filter((e) => e !== email)
    );
  }
};

export default passwordReset;
