export default {
  SET_AUTH: (state, payload) => {
    state.authorized = payload;
  },
  SET_AUTHENTICATION_ERROR: (state, payload) => {
    state.authenticationError = payload;
  },
  SET_AUTHENTICATION_MESSAGE: (state, payload) => {
    state.authenticationMessage = payload;
  },
  SET_SHOW_PASSWORD_RESET_DIALOG: (state, payload) => {
    state.showResetPwdDialog = payload;
  },
  SET_SHOW_TOKEN_REFRESH_DIALOG: (state, payload) => {
    state.showTokenRefreshDialog = payload;
  },
  SET_IS_AUTHENTICATING: (state, payload) => {
    state.isAuthenticating = payload;
  },
  SET_VERIFYING_TOKEN: (state, payload) => {
    state.verifyingToken = payload;
  },
  SET_VERIFY_TOKEN_ERROR: (state, payload) => {
    state.verifyTokenError = payload;
  },
  SET_VERIFY_TOKEN_MESSAGE: (state, payload) => {
    state.verifyTokenMessage = payload;
  },
  SET_RESENDING_TOKEN: (state, payload) => {
    state.resendingToken = payload;
  },
  SET_RESETTING_PASSWORDS: (state, payload) => {
    state.resettingPasswords = payload;
  },
};
