import {
  omitUIFields,
  convertToObject,
  generateTrigger,
  extractCredentials,
} from '@/util/action-types';
import { getExternalWorkflowActivatorCreds } from '@/util/workflowDetails';
import Cookies from "js-cookie";

const updateWorkflow = async (
  { commit, rootGetters, dispatch, state },
  { loader = true } = {}
) => {
  if (loader) commit('SET_UPDATE_WORKFLOW_UPDATING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    const allCredentials = rootGetters['credentials/AUTHENTICATIONS'];
    const selectedVersion = rootGetters['workflows/details/WORKFLOW_SELECTED_VERSION'];
    const { startSettings, nodes, workflow, credentials, alertSettings, s3StorageEnabled } = state;

    let url = `/proxy/update-workflow/`;
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const nodeList = omitUIFields(nodes);
    const nodesObj = convertToObject(nodeList);
    const actionCredentials = extractCredentials(nodesObj);
    const externalActivatorCredentials = getExternalWorkflowActivatorCreds(
      credentials,
      allCredentials
    );

    const IOStorageType = workflow.isServerless && s3StorageEnabled ? 's3' : 'common'

    const updatedWorkflow = {
      customer_id,
      workflow_id: workflow.id,
      user_id: Cookies.get('userId'),
      updatedFields: {
        selectedVersion: selectedVersion.id,
        trigger_type: startSettings.trigger_type,
        trigger: generateTrigger(startSettings),
        actions: nodesObj,
        isServerless: workflow.isServerless,
        name: workflow.name,
        description: workflow.description,
        credentials: [
          ...actionCredentials,
          ...externalActivatorCredentials.map((c) => c.id),
        ],
        alertSettings: alertSettings,
        IOStorageType,
      },
    };

    const response = await window.axios.post(url, updatedWorkflow);
    if (response && response.data.success) {
      if(response.data.version) {
        commit('SET_WORKFLOW_SELECT_VERSION', response.data.version)
      }
      dispatch('notifications/pushNotification', 'Workflow updated', {
        root: true,
      });
      commit('WORKFLOW_EDITED', false);
      return { success: true, workflowId: workflow.id };
    } else {
      dispatch('notifications/setError', response.data.message, { root: true });
      return { success: true, workflowId: workflow.id };
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
    return { success: false, message: error.message };
  } finally {
    commit('SET_UPDATE_WORKFLOW_UPDATING', false);
  }
};

export default updateWorkflow;
