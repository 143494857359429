export default {
  CUSTOMER: (state) => {
    return state.customer;
  },
  USER: (state) => {
    return state.user;
  },
  CUSTOMER_USERS: (state) => {
    return state.customer_users;
  },
  CUSTOMERS: (state) => {
    return state.customers;
  },
  SELECTED_CUSTOMER: (state) => {
    return state.selected_customer;
  },
  CUSTOMER_MAPPINGS: (state) => {
    return state.customerMappings;
  },
  CUSTOMER_USERS_LOADING: (state) => {
    return state.customerUsersLoading;
  },
  CUSTOMER_DATA_LOADING: (state) => {
    return state.customerDataLoading;
  },
  DELETE_CUSTOMER_USERS_LOADING: (state) => {
    return state.deleteCustomerUsersLoading;
  },
  ADD_CUSTOMER_USER_LOADING: (state) => {
    return state.addCustomerUserLoading;
  },
  GET_LOGGED_IN_USER_LOADING: (state) => {
    return state.getLoggedInUserLoading;
  },
  FEATURE_FLAGS: (state) => {
    return state.featureFlags;
  },
  GET_FEATURE_FLAGS_LOADING: (state) => {
    return state.getFeatureFlagsLoading;
  },
  CHANGE_CUSTOMER_INFO_LOADING: (state) => {
    return state.changeCustomerInfoLoading;
  },
};
