import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
    workspacesList: [],
    workspace: {},
    workspaceSessions: [],
    workspacePrompts: [],
    selectedPrompt: {},
    workspaceKnowledgeFiles: [],

    isWorkspacesListLoading: false,
    isWorkspaceLoading: false,
    isWorkspaceSessionsLoading: false,
    isSendMessageLoading: false,
    isWorkspaceUpdateLoading: false,
    isUpdateParticipantsLoading: false,
    isWorkspacePromptsLoading: false,
    isWorkspaceActionLoading: false,
    isWorkspaceKnowledgeFileLoading: false,
    isDeleteWorkspaceLoading: false,
});

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters,
};
